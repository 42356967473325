.loading{
    width: 100px;
    height: 20px;
    border: 1px solid white;
    border-radius: 10px;
    position: relative;

}
.container{
    width: 100%;
    display: flex;
    justify-content: center;
}
@keyframes move{
    from{
        margin-left: 2px;
        
    }
    to{
        margin-left: 80px;
    }
}
.loading div{
    height: 15px;
    width: 15px;
    margin-top: 1.47px;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 2px;
    animation-duration: 1s;
    animation-name: move;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}