.chats{
    padding-top: 20px;
    min-height: 100%;
    position: relative;
    display: flex;
}
.list{
    width: 50%;
}
@media screen and (max-width: 920px){
    .list{
        width: 100%;
        padding-right: 20px;
    }
    .list .name{

    }
}