.profile{
    display: flex;
    position: relative;
}
.requests{
    margin-top: 20px;
    position: absolute;
    right: 20px;
    width: 400px;
}
.avatar{
    width: 150px;
    height: 150px;
    background: grey;
    margin-top: 20px;
}
.info{
    margin-left: 20px;
    margin-top: 20px;
    position: relative;
}
.friends{
    width: 150px;
    height: 130px;
    border-radius: 10px;
    transition: all 0.2s;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.friends:hover{
    background-color: #3b3b3b;
}
.friends p:nth-of-type(1){
    font-size: 35px;
    text-align: center;
}
.friends p:nth-of-type(2){
    font-size: 25px;
    text-align: center;
}
.photos{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.photo{
    width: 300px;
    height: 300px;
    background-color: grey;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px solid white;
    border-radius: 10px;
}
.photo{
    position: relative;
}
.photo button{
    position: absolute;
    bottom: 0;
}
@media screen and (max-width: 920px){
    .photos{
        margin-top: 40px;
    }
    .avatar{
        width: 60px;
        height: 60px;
        position: absolute;
    }
    .editAvatar{
        margin-top: 70px;
    }
    .friends{
        position: relative;
        width: 60px;
        height: 60px;
        background-color: #1c1c1c;
        padding-top: 20px;
    }
    .friends p:nth-of-type(1){
        font-size: 15px;
    }
    .friends p:nth-of-type(2){
        font-size: 12px;
        margin-top: -15px;
    }
    .requests{
        padding: 10px;
        position: fixed;
        top: -20px;
        left: 0;
        background: black;
        width: 100vw;
        height: 100vh;
    }
    .requests div{
        display: flex;
    }
    .requests div{
        display: flex;
    }
    .requests div h3{
        margin-top: 15px;
        margin-left: 10px;
    }
}