.main{
    display: flex;
    justify-content: center;
    min-height: 100vh;
}
.login{
    width: 500px;
    height: 390px;
    border: 1px solid white;
    border-radius: 10px;
    margin-top: 50vh;
    transform: translateY(-70%);
    padding: 10px;
}
h1{
    text-align: center;
}
@media screen and (max-width: 920px){
    .login{
        transform: translateY(-50%);
    }
}
