.menu{
    padding-top: 10px;
    position: relative;
    border-right: 1px solid white;
    height: 100%;
    padding-right: 10px;
}
.bottom{
    width: 100%;
    justify-content: center;
    right: 5px;
    margin-top: 50px;
}
@media screen and (max-width: 992px){
    .menu{
        display: none;
    }
}