.messages{
    border-left: 1px solid white;
    width: 50%;
    height: 100vh;
    margin-top: -20px;
}
.mess{
    width: 200px;
    border-radius: 10px;
    background: #3b3b3b;
    margin: 10px;
    padding: 5px;
}
.login{
    color: lightgrey;
}
.text{
    margin-top: -10px;
}
.chat{
    display: flex;
    flex-direction: column;
    height: 92%;
    overflow-y: scroll;
}
.inputs{
    height: 8%;
    display: flex;
}
.inputs input{
    width: 85%;
    margin: 5px;
    height: 50px;
}
.inputs button{
    width: 15%;
    height: 50px;
}
.user{
    display: none;
}
.user h3{
    margin-top: 15px;
    margin-left: 15px;
}
@media screen and (max-width: 920px){
    .chat{
        padding-bottom: 100px;
    }
    .messages{
        position: absolute;
        width: 100vw;
        height: 100vh;
        background: black;
        border-left: 0;
    }
    .inputs{
        margin-top: -85px;
    }
    .inputs input{
        width: 70%;
    }
    .inputs button{
        width: 30%;
    }
    .user{
        display: flex;
    }
}