.button{
    width: 100%;
    height: 50px;
    font-size: 17px;
    background: none;
    transition: all 0.2s;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: 0;
}
.button:hover{
    background-color: #3b3b3b;
}
@media screen and (max-width: 920px){
    .button{
        background-color: #1c1c1c;
    }
}