* {
    color: white;
}
body{
    background-color: black;
}
#root{
    min-height: 100vh;
}
.leftColumn{
    min-height: 100vh;
}
@media screen and (max-width: 992px){
    .leftColumn{
        display: none;
    }

}